import React, { FC } from "react"

import { ICON_TYPES } from "./Icon.constants"
import styled from "styled-components"
import { getImgixUrl } from "@utils"

type IconProps = {
  color?: string,
  className?: string
  id?: string
  size?: string,
  type: ICON_TYPES
}

export { ICON_TYPES }

const IconDIV = styled.div`
  background: var(--color--text-default);
`

const Icon: FC<IconProps> = ({
  color,
  className = "",
  id,
  size = "16px",
  type,
}) => Object.keys(ICON_TYPES).includes(type) ? (
  <IconDIV
    className={`Icon Icon--${type} ${className}`}
    id={id}
    style={{
      background: color,
      width: size,
      height: size,
      mask: `center center / 100% auto url("/icons/${type}.svg") no-repeat`,
    }}
  />
) : null

export default Icon
