import { BREAKPOINTS, MEDIA_QUERIES } from "@constants"
import { generateFluidCalc } from "@utils"
import styled, { keyframes } from "styled-components"

import { SCROLLED_AND_VISIBLE_STATES } from "./SiteNav.hooks"

const MEDIA_QUERY_TOUCH_L = `@media (min-width: ${BREAKPOINTS.L}px) and (hover: none)`
const MEDIA_QUERY_TOUCH_L_PLUS = `@media (min-width: ${BREAKPOINTS.L + 100}px) and (hover: none)`

const HeaderInKeyframes = keyframes`
  0% { translate: 0 -100%; }
  100% { translate: 0 0; }
`

const ListItemExperienceInKeyframes = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

export const SiteNavHEADER = styled.header`
  --sitenav-messages-banner-offset: 0px;
  
  background: transparent;
  left: 0;
  position: absolute;
  top: var(--sitenav-messages-banner-offset);
  z-index: var(--SiteNav--z-index);
  transition: background 0.3s, transform 0.3s ease-in-out, top 0.3s;
  width: 100%;
  overflow: hidden;

  // "Pill"
  &::before {
    backdrop-filter: blur(8px);
    background-blend-mode: soft-light;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(255, 255, 255, 0.48);
    border-radius: 50px;
    content: "";
    display: block;
    height: calc(33px + var(--spacing--base-0-5) * 2);
    left: 50%;
    opacity: 0;
    position: absolute;
    top: calc(var(--spacing--base-2) - var(--spacing--base-0-5) - 1px);
    transition: all 0.3s;
    translate: -50% 0;
    width: calc(var(--container-width) + var(--spacing--base-2) * 2);
    z-index: -1;

    ${MEDIA_QUERIES.L} {
      height: calc(var(--button--height-M) + var(--spacing--base-0-5) * 2);
      width: calc(var(--container-width) + var(--spacing--base-2-5) * 2);
    }
  }

  &.scrolled--${SCROLLED_AND_VISIBLE_STATES.SHOWING},
  &.scrolled--${SCROLLED_AND_VISIBLE_STATES.HIDING} {
    &::before {
      opacity: 1;
    }
  }

  &.scrolled--${SCROLLED_AND_VISIBLE_STATES.SHOWING} {
    position: fixed;
    /* transform: translateY(0); */
    animation: ${HeaderInKeyframes} 0.3s ease-in-out forwards;
  }

  &.scrolled--${SCROLLED_AND_VISIBLE_STATES.HIDING}:not(.is-open) {
    position: fixed;
    transform: translateY(-100%);
  }

  &.is-open {
    position: fixed;
    overflow: auto;
    background: white;
    z-index: 9999;
    box-shadow:
      inset 0 0 0 1px var(--color--charcoal-200),
      var(--elevation--M);
    border-radius: 0 0 16px 16px;
    max-height: calc(100% - var(--sitenav-messages-banner-offset));
    max-height: calc(100svh - var(--sitenav-messages-banner-offset));

    ${MEDIA_QUERIES.L} {
      overflow: hidden;
    }
  }
`

export const SiteNavNAV = styled.nav`
  padding: var(--spacing--base-2) 0;
  width: 100%;
  transition: padding 0.3s;

  .is-open & {
    padding-bottom: var(--spacing--M);
  }

  li > a {
    display: block;
  }

  li.touch-menu-toggle {
    .Icon {
      background: var(--color--charcoal-600);
      transition: background 0.3s;
    }

    &:hover {
      .Icon {
        background: var(--color--charcoal-700);
      }
    }

    .Icon--close {
      display: none;
    }

    .is-open & {
      .Icon--close {
        display: block;
      }

      .Icon--menu {
        display: none;
      }
    }
  }

  /*
    start MOBILE-NAV
  */
  .mobile-nav {
    justify-content: space-between;
    align-items: center;

    ${MEDIA_QUERIES.L} {
      display: none;
    }

    li {
      margin: 0;
    }

    li.header-logo {
      svg {
        width: 126px;
        vertical-align: bottom;
        }
      }
    }
  }
  /*
    end MOBILE-NAV
  */

  /*
    start MAIN-NAV
  */
  .main-nav {
    gap: 0 var(--spacing--base-1-25);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    padding: var(--spacing--M) 0 0;
    pointer-events: none;
    position: absolute;

    .is-open & {
      opacity: 1;
      pointer-events: all;
      position: static;
    }

    ${MEDIA_QUERIES.L} {
      flex-wrap: nowrap;
      opacity: 1;
      padding: 0;
      position: static;
    }

    li {
      margin: 0;
      flex: 0 0 100%;

      .has-experience & {
        animation: ${ListItemExperienceInKeyframes} 0.3s forwards;
      }

      ${MEDIA_QUERIES.L} {
        opacity: 1;
        pointer-events: all;
        transform: none;
        flex: 0 0 max-content;

        .SiteNavDrawer {
          display: none;
        }
      }
    }

    .nav-link {
      align-items: center;
      border-radius: var(--border-radius--M);
      color: var(--color--text-light);
      display: flex;
      font-size: var(--font-size--S);
      justify-content: center;
      line-height: var(--line-height--relaxed);
      padding: var(--spacing--base-1-5) var(--spacing--base-0-75);
      text-align: center;
      transition: background-color 0.1s, color 0.1s;
      width: 100%;

      .is-open & {
        transition: background-color 0.3s, color 0.3s;
      }

      ${MEDIA_QUERIES.L} {
        width: auto;
        height: var(--button--height-M);
        padding: 0 var(--spacing--base-0-75);
      }

      .Icon.chevron {
        width: 16px;
        height: 16px;
        background: var(--color--text-light);
      }

      .is-open &:not(.header-logo):hover,
      .is-open &:not(.header-logo):active,
      &.is-active {
        background: var(--color--charcoal-100);
        color: var(--color--text-dark);

        .Icon {
          background: var(--color--text-dark);
        }
      }

      &:not(.header-logo):focus-visible {
        background: var(--color--cobalt-100);
      }
    }

    li.header-logo {
      display: none;
      margin-right: calc(var(--spacing--M) - var(--spacing--base-1-5));
      width: 124px;

      ${MEDIA_QUERIES.L} {
        width: 168px;
      }

      a {
        padding: 0;
        display: flex;
      }

      svg {
        width: 124px;

        ${MEDIA_QUERIES.L} {
          width: 168px;
          transition: transform 0.3s;
          transform-origin: left center;

          .scrolled--${SCROLLED_AND_VISIBLE_STATES.SHOWING}:not(.is-open) &,
          .scrolled--${SCROLLED_AND_VISIBLE_STATES.HIDING}:not(.is-open) & {
            transform: scale(0.892); // scale to 150px wide in "pill" mode
          }
        }
      }

      div {
        height: 33px;
      }

      ${MEDIA_QUERIES.L} {
        display: flex;
        flex: 0 0 168px;
        align-items: center;
      }
    }

    li.get-a-demo {
      margin-top: var(--spacing--M);

      ${MEDIA_QUERIES.L} {
        margin-top: 0;
        margin-left: auto;
        margin-right: calc(var(--spacing--XS) - var(--spacing--base-1-5));
      }
    }

    li.get-a-demo a {
      font-weight: var(--font-weight--semibold);
      color: var(--color--action-default);
      font-family: var(--font-family--sans-brand);

      &:hover {
        color: var(--color--action-hovered);
      }

      &:active {
        color: var(--color--action-pressed);
      }
    }

    li.free-trial {
      .Button {
        font-size: var(--font-size--S);
        transition: background-color 0.3s;
        width: 100%;
      }
    }

    li.touch-menu-toggle {
      display: none;

      button {
        padding: 0;
      }
    }

    /*
     start DESKTOP-sized TOUCH DEVICES
    */
    ${MEDIA_QUERY_TOUCH_L} {
      li.header-logo {
        margin-right: 0;
      }

      li.get-a-demo a {
        padding: 0;
      }
      
      li.free-trial {
        margin-right: calc(var(--spacing--XS) - var(--spacing--base-1-5));
      }

      li.touch-menu-toggle {
        display: flex;
        align-items: center;
      }

      li:not(.header-logo):not(.free-trial):not(.touch-menu-toggle) {
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s;

        .is-open & {
          opacity: 1;
          pointer-events: all;
        }
      }
    }

    ${MEDIA_QUERY_TOUCH_L_PLUS} {
      li.get-a-demo a {
        padding: 0 var(--spacing--base-0-75);
      }
    }
    /*
     end DESKTOP-sized TOUCH DEVICES
    */
  }
  /*
    end MAIN-NAV
  */

  /*
    start DESKTOP DRAWERS
  */
  .desktop-drawers {
    display: none;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: height 0.3s ease-in-out, opacity 0.25s;

    ${MEDIA_QUERIES.L} {
      display: grid;
    }

    > div {
      grid-area: 1 / 1 / 2 / 2;
    }

    .is-open & {
      height: calc(${generateFluidCalc(221, 250, BREAKPOINTS.L, BREAKPOINTS.XL)} + var(--spacing--M) * 2 - var(--spacing--base-2-5));
      opacity: 1;
      overflow: visible;

      ${MEDIA_QUERIES.XL} {
        height: calc(${generateFluidCalc(250, 312, BREAKPOINTS.XL, BREAKPOINTS["2XL"])} + var(--spacing--M) * 2 - var(--spacing--base-2-5));
      }
    }
  }
  /*
    end DESKTOP DRAWERS
  */
`

export const SiteNavModalOverlayBUTTON = styled.button`
  background: var(--color--charcoal-800);
  height: 100%;
  left: 0;
  min-height: 100vh;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  position: fixed;
  top: 0;
  transition: opacity 0.1s;
  width: 100%;
  z-index: calc(var(--SiteNav--z-index) - 1);

  &.is-visible {
    opacity: 0.48;
    pointer-events: all;
    transition: opacity 0.3s 0.2s;
  }
`
