import Badge, { BADGE_COLORS, BADGE_SIZES } from "@components/Badge/Badge"
import Icon, { ICON_TYPES } from "@components/Icon/Icon"
import { Link } from "gatsby"
import React, { FC, ReactElement } from "react"
import styled from "styled-components"

type SiteNavDrawerListItem = {
  icon?: string | null,
  isNew?: boolean,
  title?: string | null,
  url?: string | null,
}

type SiteNavDrawerListProps = {
  items: SiteNavDrawerListItem[],
  tabIndex?: number,
  title?: string | null
}

const SiteNavDrawerListDIV = styled.div`
  flex: 0 0 calc(50% - var(--SiteNav--drawer-column--gap-x) / 2);

  h5 {
    color: var(--color--text-light);
    font-size: var(--font-size--XS);
    font-weight: var(--font-weight--regular);
    margin-bottom: var(--spacing--base-1-25);
  }

  a {
    display: flex;
    align-items: center;
    color: var(--color--text-dark);
    margin-bottom: var(--spacing--base-0-5);

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      .Icon {
        background: var(--color--action-hovered);
      }
    }
  }

  .Icon {
    display: inline-block;
    margin: calc(var(--font-size--S) * -0.2) calc(var(--font-size--S) * 0.125) 0;
  }

  .Badge {
    margin-top: 1px;
    margin-left: 6px;
  }

  .company & {
    flex: 0 0 100%;
  }
`

const SiteNavDrawerList: FC<SiteNavDrawerListProps> = ({
  items,
  tabIndex,
  title,
}) => {
  const renderItemChildren = (item: SiteNavDrawerListItem): ReactElement => (
    <>
      {item.icon && (
        <Icon
          size="calc(var(--font-size--S) * 1.25)"
          type={ICON_TYPES[item.icon as ICON_TYPES]}
        />
      )}
      {item.title}
      {item.isNew && (
        <Badge
          color={BADGE_COLORS.PURPLE}
          isInverted
          size={BADGE_SIZES.XS}
        >
          NEW
        </Badge>
      )}
    </>
  )

  return (
    <SiteNavDrawerListDIV className="SiteNavDrawerList">
      <h5 className="font-family--mono">
        {title}
      </h5>
      {items.map(item => (!item.url || !item.title) ? null :
        item.url.includes("//") ? (
          <a
            className="type--text-medium-S"
            href={item.url}
            key={item.url}
            tabIndex={tabIndex}
          >
            {renderItemChildren(item)}
          </a>
        ) : (
          <Link
            className="type--text-medium-S"
            key={item.url}
            tabIndex={tabIndex}
            to={item.url}
          >
            {renderItemChildren(item)}
          </Link>
        ))}
    </SiteNavDrawerListDIV>
  )
}

export default SiteNavDrawerList
